<template>
    <div>
        <validation-observer ref="loginValidation">
            <div class="form-heading">
                <div class="form-heading-emoji-wrapper">
                    <img src="/emoji/confetti.png" alt="confetti-img">
                </div>
                <div class="form-title">Welcome!</div>
            </div>

            <b-form @submit.prevent="login">
                <validated-form-input
                    label="E-mail"
                    input-id="email-input"
                    validation-name="e-mail"
                    placeholder="Enter your e-mail"
                    rules="required|email"
                    type="email"
                    v-model="userEmail"
                />
                <validated-form-input
                    label="Password"
                    input-id="password-input"
                    validation-name="password"
                    placeholder="Enter your password"
                    type="password"
                    v-model="password"
                />

                <b-button type="submit" class="w-100 mt-1 mt-md-2 submit-form-btn" :disabled="loading">
                    <template v-if="loading">
                        <b-spinner small class="mr-50"/>
                        <span class="align-middle">Loading</span>
                    </template>
                    <span v-else>Sign In</span>
                </b-button>

                <p class="text-center mt-2"><span>New on our platform? </span>
                    <router-link :to="{ name: 'auth.register' }"><span> Create an account</span></router-link>
                </p>
            </b-form>
        </validation-observer>
    </div>
</template>


<script>
import {ValidationObserver} from "vee-validate";
import {BForm, BButton, BSpinner} from "bootstrap-vue";
import AuthCardLeft from "@/layouts/components/auth/AuthCardLeft";
import ValidatedFormInput from "@/layouts/components/shared/ValidatedFormInput";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: 'AuthLogin',
    components: {
        BForm,
        BButton,
        BSpinner,
        ValidationObserver,
        AuthCardLeft,
        ValidatedFormInput
    },
    data() {
        return {
            userEmail: '',
            password: '',
            loading: false
        }
    },
    methods: {
        resetForm() {
            this.userEmail = '';
            this.password = '';
            this.$refs.loginValidation.reset();
        },
        redirectAuthUserToAccordingRoute() {
            if (!this.$store.getters['auth/hasActiveSubscription']) {
                return this.$router.push({ name: 'auth.setup-payments' });
            }

            if (this.$store.getters['auth/hasActiveSubscription'] && !this.$store.getters['auth/hasSetupApiKey']) {
                return this.$router.push({ name: 'auth.setup-api-key' });
            }

            if (this.$store.getters['auth/hasActiveSubscription'] && this.$store.getters['auth/hasSetupApiKey'] && !this.$store.getters['auth/hasConfiguredLocations']) {
                return this.$router.push({ name: 'auth.setup-locations' });
            }

            return this.$router.push({ name: 'dashboard' });
        },
        async login() {
            const isFormValid = this.$refs.loginValidation.validate();

            if (!isFormValid || this.loading) return;

            try {
                this.loading = true;
                const { userEmail: email, password } = this.$data;
                await this.$store.dispatch('auth/login', { email, password });
                this.loading = false;

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Logged in successfully!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        hideClose: true
                    },
                });

                return this.redirectAuthUserToAccordingRoute();
            } catch (err) {
                this.loading = false;
                if (err.response && +err.response.status === 400) {
                    this.resetForm();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: err.response.data.message,
                            icon: 'XIcon',
                            variant: 'danger',
                            hideClose: true
                        },
                    });
                }
            }
        }
    }
}
</script>
